/**
 * Created by osirvent on 18/11/2015.
 */
angular
    .module('annexaApp')
    .constant('TerritoryModals',{
    	addressNew: {
            title: 'global.territory.list.new',
            size: 'modal-xl',
            icon: 'fa fa-at',
            annexaFormly: {
                fields: [
                	{
                        key: 'row_address',
                        className: 'row',
                        templateOptions: {},
                        fieldGroup: [
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        }
   });