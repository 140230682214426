angular
    .module('annexaApp')
    .factory('TerritoryFactory',['$q', '$http','Language', 'HelperService', '$filter', '$rootScope', 'RestService', 'TerritoryModals', 'annexaFormFactory', 'AnnexaFormlyFactory', 'CacheFactory', 'GlobalDataFactory', 'AnnexaPermissionsFactory', '$state', function($q, $http, Language, HelperService, $filter, $rootScope, RestService, TerritoryModals, annexaFormFactory, AnnexaFormlyFactory, CacheFactory, GlobalDataFactory, AnnexaPermissionsFactory, $state) {
        var factory = {};
        factory.address = undefined;
        factory.countries = [];
        factory.states = [];
        factory.streetTypes = [];
        factory.pseudoVies = [];
		factory.territorialGroups = [];
		factory.territorialGroupTypes = [];
        factory.defaultTown = undefined;
        factory.activeOptions = [
            {id:'', description:'global.literals.empty'},
            {id:'1', description:'global.literals.yes'},
            {id:'0', description:'global.literals.no'}
        ];
        
        factory.getTerritory = function(id){
        	factory.address = undefined;
            return RestService.findOne('Address', id).then(function (data) {
                factory.address = JSOG.decode(data.data);
            }).catch(function (error) {
                console.error(error);
            });
        }
        
        factory.initializeLists = function(){
        	var promises = [];
        	
        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('StreetCountry')){
        		var cache = CacheFactory.get('globalDataCache').get('StreetCountry');
        		if(cache && cache.length > 0){
        			factory.countries = JSOG.decode(angular.fromJson(cache));
        		}
        	}
        	if(factory.countries.length == 0){
        		promises.push(RestService.findAll('StreetCountry'));
        	}
        	
        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('StreetState')){
        		var cache = CacheFactory.get('globalDataCache').get('StreetState');
        		if(cache && cache.length > 0){
        			factory.states = JSOG.decode(angular.fromJson(cache));
        		}
        	}
        	if(factory.states.length == 0){
        		promises.push(RestService.findAll('StreetState'));
        	}

        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('StreetStreetType')){
        		var cache = CacheFactory.get('globalDataCache').get('StreetStreetType');
        		if(cache && cache.length > 0){
        			factory.streetTypes = JSOG.decode(angular.fromJson(cache));
        		}
        	}
        	if(factory.streetTypes.length == 0){
        		promises.push(RestService.findAll('StreetStreetType'));
        	}
        	if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_town && $rootScope.app.configuration.default_town.value){
        		promises.push(RestService.findOne('StreetCity',$rootScope.app.configuration.default_town.value));
        	}
        	
            return $q.all(promises)
                .then(function(data) {
                	var dataPos = 0;
                	if(factory.countries.length == 0) {
                		factory.countries = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('StreetCountry', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }

                	if(factory.states.length == 0) {
                        factory.states = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('StreetState', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }

                	if(factory.streetTypes.length == 0) {
                        factory.streetTypes = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('StreetStreetType', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                	try {
                    	if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_town && $rootScope.app.configuration.default_town.value){
                    		factory.defaultTown = JSOG.decode(data[dataPos].data)
                    		dataPos++;
                    	}
                	} catch (exception) {
                		if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_town && $rootScope.app.configuration.default_town.value){
                    		RestService.findOne('StreetCity',$rootScope.app.configuration.default_town.value).then(function(data) {
                    			factory.defaultTown = JSOG.decode(data.data)
                    		}).catch(function(error) {
                    			
                            });
                		}
                	}
                	
                }).catch(function(error) {
                    factory.countries = [];
                    factory.states = [];
                    factory.streetTypes = [];
                    factory.defaultTown = undefined;
                });
        }

        
        factory.initializeListsState = function(){
        	var promises = [];
        	
        	factory.countries.length = 0;
        	promises.push(RestService.findAll('StreetCountry'));
        	return $q.all(promises)
                .then(function(data) {
                	var dataPos = 0;
                	if(factory.countries.length == 0) {
                		factory.countries = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('StreetCountry', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                }).catch(function(error) {
                    factory.countries = [];
                });
        }

        factory.initializeListsCity = function(){
        	var promises = [];
        	
        	factory.countries.length = 0;
        	promises.push(RestService.findAll('StreetCountry'));

        	factory.states.length = 0;
        	promises.push(RestService.findAll('StreetState'));
        	
        	factory.pseudoVies.length = 0;
        	promises.push(RestService.findAll('PseudoVia'));
        	
			factory.territorialGroupTypes.length = 0;
        	promises.push(RestService.findAll('TerritorialGroupType'));
			
			factory.territorialGroups.length = 0;
        	promises.push(RestService.findAll('TerritorialGroup'));
        	return $q.all(promises)
                .then(function(data) {
                	var dataPos = 0;
                	if(factory.countries.length == 0) {
                		factory.countries = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('StreetCountry', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }

                	if(factory.states.length == 0) {
                        factory.states = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('StreetState', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                	
                	if(factory.pseudoVies.length == 0) {
                        factory.pseudoVies = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('PseudoVia', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }

					if(factory.territorialGroupTypes.length == 0) {
                        factory.territorialGroupTypes = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

					if(factory.territorialGroups.length == 0) {
                        factory.territorialGroups = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }
                }).catch(function(error) {
                    factory.countries = [];
                    factory.states = [];
                    factory.pseudoVies = [];
					factory.territorialGroupTypes = [];
					factory.territorialGroups = [];
                });
        }

        factory.initializeListsStreet = function(){
        	var promises = [];
        	
        	factory.countries.length = 0;
        	promises.push(RestService.findAll('StreetCountry'));

        	factory.states.length = 0;
        	promises.push(RestService.findAll('StreetState'));


        	factory.streetTypes.length = 0;
        	promises.push(RestService.findAll('StreetStreetType'));

        	return $q.all(promises)
                .then(function(data) {
                	var dataPos = 0;
                	if(factory.countries.length == 0) {
                		factory.countries = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('StreetCountry', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }

                	if(factory.states.length == 0) {
                        factory.states = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('StreetState', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }

                	if(factory.streetTypes.length == 0) {
                        factory.streetTypes = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('StreetStreetType', data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                }).catch(function(error) {
                    factory.countries = [];
                    factory.states = [];
                    factory.streetTypes = [];
                });
        }

		factory.initializeListsTerritorialGroup = function(){
        	var promises = [];
        	
        	factory.territorialGroupTypes.length = 0;
        	promises.push(RestService.findAll('TerritorialGroupType'));

        	return $q.all(promises)
                .then(function(data) {
                	var dataPos = 0;
                	if(factory.territorialGroupTypes.length == 0) {
                		factory.territorialGroupTypes = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }
                }).catch(function(error) {
                    factory.territorialGroupTypes = [];
                });
        }

        
        factory.getStreet = function(id){
        	var deferrend = $q.defer();
        	var promises = [];
        	promises.push(RestService.findOne('StreetStreet', id));
        	promises.push($http({method: 'GET',url: './api/territory/street_street_numbers/by_street/'+id}));
        	$q.all(promises).then(function(data) {
                	var dataPos = 0;
                	var street = JSOG.decode(data[dataPos].data);
                    dataPos++;
                    var numbers =  JSOG.decode(data[dataPos].data);
                    dataPos++;
                    if(numbers && numbers.length > 0){
                    	street.numbers = numbers;
                    }else{
                    	street.numbers = [];
                    }
                    deferrend.resolve(street);
            }).catch(function(error) {
            	deferrend.reject(error);
            });
        	return deferrend.promise;
        }
        
        factory.changeState = function(url){
        	var deferrend = $q.defer();
        	$http({
                method: 'PUT',
                url: url,
            }).success(function (data, status) {
                deferrend.resolve(JSOG.decode(data.data));
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }
        
        factory.getCitiesByState = function(state){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/territory/getCitiesByState',
                params: {
                    state: state
                }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.getStreetsByCity = function(city){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/territory/getStreetsByCity',
                params: {
                    city: city
                }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        factory.getStreetsByCityAndStreetType = function(city, streetType){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/territory/getStreetsByCityAndStreetType',
                params: {
                    city: city,
                    streetType: streetType
                }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        factory.viewAddress = function(id, territory){
        	RestService.findOne('Address', id).then(function (data) {
        		factory.newAddress(JSOG.decode(data.data), undefined, territory);
            }).catch(function (error) {
                console.error(error);
            });
        }
        factory.newAddress = function(addressOrig, preCloseFunction, territory, addressType){
        	var saveNewAddress = function(){
            	var self = this;
                
            	if (this.annexaFormly.form.$valid) {
            		var address = {};
            		var addressAux = this.annexaFormly.model.row_address;
            		if(addressAux 
            				&& (
            						(addressAux.country 
	            						&& (addressAux.validState || addressAux.stateAddress) 
	            						&& (addressAux.validCity || addressAux.city) 
	            						&& addressAux.postalCode 
	            						&& (((addressAux.validStreet || addressAux.street) && addressAux.streetType) || ( addressAux.validPseudoVia || addressAux.pseudoVia ))
	            						&& (addressAux.numberAddress || addressAux.numberAddress2 || addressAux.withoutNumber_1 || addressAux.kilometer)
	            					)
	            					|| addressAux.cadastralReference
	            					|| (addressAux.coordinateX && addressAux.coordinateY)
            					)
      				){
            			if(addressAux.addressType){
            				address.addressType = {id: addressAux.addressType};
            			}
            			if(addressAux.country){
            				address.country = {id: addressAux.country};
            			}
            			if (addressAux.validState) {
            				address.validState = {id: addressAux.validState};
            			}else if (addressAux.stateAddress){
            				address.stateAddress = addressAux.stateAddress;
            			}
            			if(addressAux.validCity) {
            				address.validCity = {id: addressAux.validCity};
            			}else if (addressAux.city){
            				address.city = addressAux.city;
            			}
            			if(addressAux.streetType) {
            				address.streetType = {id: addressAux.streetType};
            			}
            			if(addressAux.validStreet) {
            				address.validStreet = {id: addressAux.validStreet};
            			}else if (addressAux.street){
            				address.street = addressAux.street;
            			}
            			if(addressAux.validPseudoVia){
            				address.validPseudoVia = {id:addressAux.validPseudoVia};
            			}else if(addressAux.pseudoVia){
            				address.pseudoVia = addressAux.pseudoVia;
            			}
            			address.isValidNumber = false;
            			if(addressAux.withoutNumber_1){
            				address.numberAddress = undefined;
            				address.numberAddress2 = undefined;
            				address.bis = undefined;
            				address.bis2 = undefined;
            				address.withoutNumber = true;
            			}else{
            				address.numberAddress = addressAux.numberAddress;
            				address.numberAddress2 = addressAux.numberAddress2;
            				address.bis = addressAux.bis;
            				address.bis2 = addressAux.bis2;
            				address.withoutNumber = false;
            			}
            			address.kilometer = addressAux.kilometer;
            			address.floor = addressAux.floor;
            			address.block = addressAux.block;
            			address.door = addressAux.door;
            			address.portal = addressAux.portal;
            			address.stair = addressAux.stair;
            			address.postalCode = addressAux.postalCode;
            			address.observations = addressAux.observations;
            			address.cadastralReference = addressAux.cadastralReference;
            			address.coordinateX = addressAux.coordinateX;
            			address.coordinateY = addressAux.coordinateY;
            			address.territory = addressAux.territory;
            			address.observations = addressAux.observations;
            			if(addressOrig && addressOrig.id){
            				RestService.update('./api/territory/'+addressOrig.id, address).then(function(data) {
	            				if(preCloseFunction){
	            					preCloseFunction(data);
	            				}else{
	            					$rootScope.$broadcast('annexaAdminBaseReloadTable', {});
	            				}
	                        	self.close();
	                        }).catch(function(error){
	                        	self.alerts.length = 0;
	                        	if(error && error.data && error.data.message == 'Exist address'){
	                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorExistAddress') });
	                        	}else if(error && error.data && error.data.message == 'Invalid address'){
	                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorRequiredAddressFields') });
	                        	}else if(error && error.data && error.data.message == 'Invalid address type'){
	                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorAddressTypeError') });
	                        	}else if(error && error.data && error.data.message == 'Number, number2 is not valid number'){
	                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorInvalidNumbers') });
	                        	}else if(error && error.data && error.data.message == 'User Permission error'){
	                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorUserPermissionEdit') });
	                        	}else{
	                        		self.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
	                        	}
	                        });
            			}else{
            				if(territory){
	            				address.active = true;
		            			RestService.insert('./api/territory', address).then(function(data) {
		            				if(preCloseFunction){
		            					preCloseFunction(data);
		            				}else{
		            					$rootScope.$broadcast('annexaAdminBaseReloadTable', {});
		            				}
		                        	self.close();
		                        }).catch(function(error){
		                        	self.alerts.length = 0;
		                        	if(error && error.data && error.data.message == 'Exist address'){
		                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorExistAddress') });
		                        	}else if(error && error.data && error.data.message == 'Invalid address'){
		                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorRequiredAddressFields') });
		                        	}else if(error && error.data && error.data.message == 'Invalid address type'){
		                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorAddressTypeError') });
		                        	}else if(error && error.data && error.data.message == 'Number, number2 is not valid number'){
		                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorInvalidNumbers') });
		                        	}else if(error && error.data && error.data.message == 'User Permission error'){
		                        		self.alerts.push({ msg: $filter('translate')('global.territory.list.errorUserPermissionCreate') });
		                        	}else{
		                        		self.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
		                        	}
		                        });
	            			}else{
	        		            $http({
	        		                method: 'PUT',
	        		                url: './api/territory/formatAddress',
	        		                data: JSOG.encode(address)
	        		            }).success(function(data, status) {
	        		            	if(preCloseFunction){
	        		            		address.addresslanguage1 = data.address;
	        		            		address.addresslanguage2 = data.address;
	        		            		address.addresslanguage3 = data.address;
		            					preCloseFunction(address);
		            				}
		                        	self.close();
	        		            }).error(function(msg, code) {
	        		            	if(preCloseFunction){
	        		            		address.addresslanguage1 = "NotTranslated";
	        		            		address.addresslanguage2 = "NotTranslated";
	        		            		address.addresslanguage3 = "NotTranslated";
		                        		preCloseFunction(address);
		                        	}
		                        	self.close();
	        		            });
	            			}
            			}
            		}else{
            			self.alerts.push({ msg: $filter('translate')('global.territory.list.errorRequiredAddressFields') });
            		}
                }else{
                	self.alerts.push({ msg: $filter('translate')('global.territory.list.errorRequiredAddressFields') });
                }
            }
        	var hidePostalGroup = function($viewValue, $modelValue, scope){
        		var hide = false;
        		if(!hideNotTerritory(undefined, undefined, scope) && scope.model.hidePostal){
        			hide = true;
        		}
        		return hide;
        	}
        	
        	var hideCadastralReferenceGroup = function($viewValue, $modelValue, scope){
        		var hide = false;
        		if(hideNotTerritory(undefined, undefined, scope) || scope.model.hideCadastralReference){
        			hide = true;
        		}
        		return hide;
        	}
        	var hideCoordinatesGroup = function($viewValue, $modelValue, scope){
        		var hide = false;
        		if(hideNotTerritory(undefined, undefined, scope) || scope.model.hideCoordinates){
        			hide = true;
        		}
        		return hide;
        	}
        	
        	var hideFunction = function(list, object, query, negate) {
        		var hide = true;
            	if(object){
                    var objects = $linq(list).where(query).toArray();
                    if(objects && objects.length > 0){
                    	hide = false;
                    }
                }
            	if(negate){
            		hide = !hide;
            	}
                return hide;
            }
        	
        	var hideState = function($viewValue, $modelValue, scope) {
        		if(scope.model && scope.model.country && !hidePostalGroup(undefined, undefined, scope)){
        			return hideFunction(factory.states, scope.model.country,"x => x.country && x.country.id == "+scope.model.country, false);
        		}else{
        			return true;
        		}
            }
        	
        	var hideStateString = function($viewValue, $modelValue, scope) {
        		if(hidePostalGroup(undefined, undefined, scope)){
        			return true;
        		}else if(scope.model && scope.model.country){
        			return hideFunction(factory.states, scope.model.country,"x => x.country && x.country.id == "+scope.model.country, true);
        		}else{
        			return false;
        		}
            }
        	        	
        	var hideCity = function($viewValue, $modelValue, scope) {
        		var hide = true;
        		if(scope.model && scope.model.hasCities && !hidePostalGroup(undefined, undefined, scope)){
        			hide = false;
        		}
        		return hide;
            }
        	
        	var hideCityString = function($viewValue, $modelValue, scope) {
        		var hide = true;
        		if(scope.model && !scope.model.hasCities && !hidePostalGroup(undefined, undefined, scope)){
        			hide = false;
        		}
        		return hide;
            }
        	
        	var hideStreet = function($viewValue, $modelValue, scope) {
        		var hide = true;
        		if(scope.model && scope.model.hasStreets && !hidePostalGroup(undefined, undefined, scope)){
        			hide = false;
        		}
        		return hide;
            }
        	
        	var hideStreetString = function($viewValue, $modelValue, scope) {
        		var hide = true;
        		if(scope.model && !scope.model.hasStreets && !hidePostalGroup(undefined, undefined, scope)){
        			hide = false;
        		}
        		return hide;
            }
        	
        	var hideStreetType = function($viewValue, $modelValue, scope) {
        		var hide = true;
        		if(factory.streetTypes && factory.streetTypes.length > 0 && !hidePostalGroup(undefined, undefined, scope)){
        			hide = false;
        		}
        		return hide;
            }
        	
        	var hidePseudoViaString = function($viewValue, $modelValue, scope) {
        		var hide = true;
        		if(scope.model && !scope.model.hasPseudoVia && !hidePostalGroup(undefined, undefined, scope)){
        			hide = false;
        		}
        		return hide;
            }
        	
        	var hidePseudoVia = function($viewValue, $modelValue, scope) {
        		var hide = true;
        		if(scope.model && scope.model.hasPseudoVia && !hidePostalGroup(undefined, undefined, scope)){
        			hide = false;
        		}
        		return hide;
            }
        	
        	var showWithoutNumber = function($viewValue, $modelValue, scope) {
        		var hide = true;
        		if(!hidePostalGroup(undefined, undefined, scope)){
        			hide = ((scope.model.withoutNumber_1)?true:false);
        		}
                return hide;
            }
        	
        	var hideWithoutNumber = function($viewValue, $modelValue, scope) {
        		var hide = true;
        		if(!hidePostalGroup(undefined, undefined, scope)){
        			hide = !scope.model.withoutNumber_1;
        		}
                return hide;
            }
        	
        	var hideNotTerritory = function($viewValue, $modelValue, scope) {
                return ((!scope.model.territory)?true:false);
            }
        	
            var modal = angular.copy(TerritoryModals.addressNew);
            if(modal && modal.annexaFormly && modal.annexaFormly.fields && modal.annexaFormly.fields[0].fieldGroup){
            	modal.annexaFormly.model = {row_address: ((addressOrig)?addressOrig:((factory.defaultTown && factory.defaultTown.id)?{country:factory.defaultTown.state.country,validState:factory.defaultTown.state,validCity:factory.defaultTown}:{}))};
            	((modal.annexaFormly.model.row_address.withoutNumber)?modal.annexaFormly.model.row_address.withoutNumber_1 = true:modal.annexaFormly.model.row_address.withoutNumber_1 = false);
            	((modal.annexaFormly.model.row_address.country && modal.annexaFormly.model.row_address.country.id)?modal.annexaFormly.model.row_address.country = modal.annexaFormly.model.row_address.country.id:modal.annexaFormly.model.row_address.country = undefined);
            	((modal.annexaFormly.model.row_address.validState && modal.annexaFormly.model.row_address.validState.id)?modal.annexaFormly.model.row_address.validState = modal.annexaFormly.model.row_address.validState.id:modal.annexaFormly.model.row_address.validState = undefined);
            	((modal.annexaFormly.model.row_address.validCity && modal.annexaFormly.model.row_address.validCity.id)?modal.annexaFormly.model.row_address.validCity = modal.annexaFormly.model.row_address.validCity.id:modal.annexaFormly.model.row_address.validCity = undefined);
            	((modal.annexaFormly.model.row_address.validStreet && modal.annexaFormly.model.row_address.validStreet.id)?modal.annexaFormly.model.row_address.validStreet = modal.annexaFormly.model.row_address.validStreet.id:modal.annexaFormly.model.row_address.validStreet = undefined);
            	((modal.annexaFormly.model.row_address.streetType && modal.annexaFormly.model.row_address.streetType.id)?modal.annexaFormly.model.row_address.streetType = modal.annexaFormly.model.row_address.streetType.id:modal.annexaFormly.model.row_address.streetType = undefined);
            	((modal.annexaFormly.model.row_address.validPseudoVia && modal.annexaFormly.model.row_address.validPseudoVia.id)?modal.annexaFormly.model.row_address.validPseudoVia = modal.annexaFormly.model.row_address.validPseudoVia.id:modal.annexaFormly.model.row_address.validPseudoVia = undefined);
            	((addressOrig && addressOrig.id)?modal.annexaFormly.model.row_address.territory = addressOrig.territory:modal.annexaFormly.model.row_address.territory = ((territory != undefined && territory != null)?territory:true));
            	((addressOrig && addressOrig.addressType && addressOrig.addressType.id)?modal.annexaFormly.model.row_address.addressType = addressOrig.addressType.id:modal.annexaFormly.model.row_address.addressType = ((addressType)?addressType:undefined));
            	modal.annexaFormly.model.row_address.hidePostal = false;
            	modal.annexaFormly.model.row_address.hideCadastralReference = false;
            	modal.annexaFormly.model.row_address.hideCoordinates = false;
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
				var classAddressType = "col-sm-12 p-l-2 p-r-2";
				if(addressOrig && addressOrig.id && modal.annexaFormly.model && modal.annexaFormly.model.row_address && modal.annexaFormly.model.row_address.territory){
            		classAddressType = "col-sm-9 p-l-2 p-r-2";
				}
                modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'addressType', fieldType: 'select', data:GlobalDataFactory.addressTypes, colClass: classAddressType, required: true, label: 'global.thirds.literals.addressType', defaultValue: ((addressType)?addressType:(($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.defaultAddressType && $rootScope.app.configuration.defaultAddressType.value)?$rootScope.app.configuration.defaultAddressType.value:undefined)), hideExpression: hideNotTerritory ,
              	  onSelected: function($item) {
            		  $rootScope.$broadcast('modifyAddressTypeEvent', {item: $item});
                  },
                  controller: ['$scope', function($scope) {
                	  var calculateAddressType = function(addressType){
                		  var hidePostal = false;
                    		if(!hideNotTerritory(undefined, undefined, $scope)){
                    			hidePostal = true;
                    			if(addressType && GlobalDataFactory.addressTypes && GlobalDataFactory.addressTypes.length > 0){
                    				var ataux = $linq(GlobalDataFactory.addressTypes).firstOrDefault(undefined, " x=> x.id == "+addressType);
                    				if(ataux && (ataux.postalGroup == 'REQUIRED' || ataux.postalGroup == 'OPTIONAL')){
                    					hidePostal = false;
                    				}
                    			}
                    		}
                    		$scope.model.hidePostal = hidePostal;
                  		var hideCadastralReference = true;
                    		if(!hideNotTerritory(undefined, undefined, $scope)){
                    			if(addressType && GlobalDataFactory.addressTypes && GlobalDataFactory.addressTypes.length > 0){
                    				var ataux = $linq(GlobalDataFactory.addressTypes).firstOrDefault(undefined, " x=> x.id == " +addressType);
                    				if(ataux && (ataux.cadastralReferenceGroup == 'REQUIRED' || ataux.cadastralReferenceGroup == 'OPTIONAL')){
                    					hideCadastralReference = false;
                    				}
                    			}
                    		}
                    		$scope.model.hideCadastralReference = hideCadastralReference;
                  		var hideCoordinates = true;
                    		if(!hideNotTerritory(undefined, undefined, $scope)){
                    			if(addressType && GlobalDataFactory.addressTypes && GlobalDataFactory.addressTypes.length > 0){
                    				var ataux = $linq(GlobalDataFactory.addressTypes).firstOrDefault(undefined, " x=> x.id == "+addressType);
                    				if(ataux && (ataux.coordinatesGroup == 'REQUIRED' || ataux.coordinatesGroup == 'OPTIONAL')){
                    					hideCoordinates = false;
                    				}
                    			}
                    		}
                    		$scope.model.hideCoordinates = hideCoordinates;
                	  }
                	  calculateAddressType($scope.model.addressType);
                	  $scope.$on('modifyAddressTypeEvent', function(event, args) {
                		  if(args && args.item && args.item.id){
                			  calculateAddressType(args.item.id);
                		  }
                     });
              	}]}, modal.annexaFormly.model));
				if(addressOrig && addressOrig.id && modal.annexaFormly.model && modal.annexaFormly.model.row_address && modal.annexaFormly.model.row_address.territory){
					modal.annexaFormly.fields[0].fieldGroup.push({key: 'auditAddress', type: 'annexaLabelButton', className: 'col-sm-3 m-t', templateOptions: {buttonLabel: 'global.documents.new.audit.audit',executeFunction: function() {
						window.open($state.href('annexa.admin.audit_address', {"objectId": addressOrig.id }), '_blank');
					}}});
				}
                var countries = HelperService.sortArrayByLanguage(factory.countries);
                modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'country', fieldType: 'select', data:countries, colClass: 'col-sm-12 p-l-2 p-r-2', required: false, label: 'global.thirds.literals.country', defaultValue: (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_country && $rootScope.app.configuration.default_country.value)?$rootScope.app.configuration.default_country.value:''), hideExpression: hidePostalGroup,
                	  onSelected: function($item) {
                		  $rootScope.$broadcast('modifyCountryTerritoryEvent', {item: $item});
                      }
                }, modal.annexaFormly.model));
		        modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'validState', fieldType: 'select', data:[], colClass: 'col-sm-12 p-l-2 p-r-2', required: false, label: 'global.thirds.literals.state', hideExpression: hideState, 
		        	controller: ['$scope', function($scope) {
		        		var calculateStates = function(){
		        			if(factory.states && factory.states.length > 0) {
                                var statesAux = [];
                                statesAux = HelperService.sortArrayByLanguage($linq(factory.states).where("x => x.country && x.country.id == "+$scope.model.country).toArray());
                                if(statesAux && statesAux.length > 0){
                                	$scope.to.options = statesAux;
                                	$scope.model.hasCities = true;
                                    $scope.model.hasStreets = true;
                                }else{
                                	$scope.to.options = [];
                                	$scope.model.hasCities = false;
                                    $scope.model.hasStreets = false;
                                }
                            }else{
                            	$scope.to.options = [];
                            	$scope.model.hasCities = false;
                                $scope.model.hasStreets = false;
                            }
		        		}
		        		if($scope.model && $scope.model.country){
                            calculateStates();
                        }
		        		$rootScope.$on('modifyCountryTerritoryEvent', function(event, args) {
                            $scope.model.validState = undefined;
                            calculateStates();
                        });
                	}],
                	onSelected: function($item) {
                        $rootScope.$broadcast('modifyStateTerritoryEvent', {item: $item});
                    }
	        	}, modal.annexaFormly.model));
		        modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'stateAddress', fieldType: 'text', colClass: 'col-sm-12 p-l-2 p-r-2', required: false, label: 'global.thirds.literals.state', hideExpression: hideStateString,
		            controller: ['$scope', function($scope) {
		            	$rootScope.$on('modifyCountryTerritoryEvent', function(event, args) {
                            $scope.model.stateAddress = undefined;
                        });
		            }]
		        }, modal.annexaFormly.model));
		        modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'validCity', fieldType: 'select', data:[], labelProp:'name', colClass: 'col-sm-9 p-l-2', required: false, label: 'global.literals.city', hideExpression: hideCity,
		            controller: ['$scope', function($scope) {
		            	var calculateCities = function(){
		            		if($scope.model && $scope.model.validState){
		            			factory.getCitiesByState($scope.model.validState).then(function (data) {
			            		     var citiesAux = JSOG.decode(data);
	                                 citiesAux = citiesAux.sort(function (a, b) {
		                                	  return a.name.localeCompare(b.name);
		                                 });
	                                 if (citiesAux.length > 0) {
	                                     $scope.to.options = citiesAux;
	                                     $scope.model.hasCities = true;
	                                     $scope.model.hasStreets = true;
	                                     if($scope.model.validCity){
	                                    	 $rootScope.$broadcast('calculateCitiesTerritoryEvent', {item:$linq(citiesAux).firstOrDefault(undefined,"x => x.id == "+$scope.model.validCity), city:$scope.model.validCity});
	                                     }
	                                 } else {
	                                     $scope.to.options = [];
	                                     $scope.model.hasCities = false;
	                                     $scope.model.hasStreets = false;
	                                 }
	                             }).catch(function (error) {
	                                 $scope.to.options = [];
	                                 $scope.model.hasCities = false;
	                                 $scope.model.hasStreets = false;
	                             });
		            		}else{
		            	         $scope.to.options = [];
                                 $scope.model.hasCities = false;
                                 $scope.model.hasStreets = false;
		            		}
                            
		        		}
		        		if($scope.model && $scope.model.validState){
		        			calculateCities();
                        }
		            	$rootScope.$on('modifyCountryTerritoryEvent', function(event, args) {
		            		$scope.model.validCity = undefined;
                            $scope.to.options = [];
                        });
		            	$rootScope.$on('modifyStateTerritoryEvent', function(event, args) {
		            		$scope.model.validCity = undefined;
		            		calculateCities();
                        });
		            }],
                    onSelected: function($item) {
                        $rootScope.$broadcast('modifyCityTerritoryEvent', { item: $item });
                    }
		        }, modal.annexaFormly.model));
		        modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'city', fieldType: 'text', colClass: 'col-sm-9 p-l-2', required: false, label: 'global.literals.city', hideExpression: hideCityString,
		            controller: ['$scope', function($scope) {
		            	$rootScope.$on('modifyCountryTerritoryEvent', function(event, args) {
		            		$scope.model.city = undefined;
                        });
		            	$rootScope.$on('modifyStateTerritoryEvent', function(event, args) {
		            		$scope.model.city = undefined;
                        });
		            }]
		        }, modal.annexaFormly.model));
		        modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'postalCode', fieldType: 'text', colClass: 'col-sm-3 p-r-2', required: false, label: 'global.thirds.literals.postalCode', hideExpression: hidePostalGroup }, modal.annexaFormly.model));
		        var streetTypes = HelperService.sortArrayByLanguage(factory.streetTypes);
		        modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'streetType', fieldType: 'select', data:streetTypes, colClass: 'col-sm-12 p-l-2 p-r-2', required: false, label: 'global.thirds.literals.streetType', hideExpression: hideStreetType,
		            controller: ['$scope', function($scope) {
		            	  $rootScope.$on('modifyStreetTerritoryEvent', function (event, args) {
                              if($scope.model.validStreet && args.item &&  args.item.streetType && args.item.streetType.id) {
                            	  $scope.model.streetType = args.item.streetType.id;
                              } else {
                            	  $scope.model.streetType = undefined;
                              }
                          });
		            }],
		            onSelected: function($item) {
                        $rootScope.$broadcast('modifyStreetTypeTerritoryEvent', { item: $item });
                    }
		        }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'validStreet', fieldType: 'select', data:[], labelProp:'name', titleLabelProp:'streetTypeLabel', colClass: 'col-sm-12 p-l-2 p-r-2', required: false, label: 'global.thirds.literals.streetName', hideExpression: hideStreet,
		            controller: ['$scope', function($scope) {
		            	var addStreetType = function(streets){
							var langColumn = Language.getActiveColumn();
							if(streets && streets.length > 0 && langColumn){
								_.forEach(streets, function(street){
									if(street.streetType && street.streetType[langColumn]){
										street.streetTypeLabel = street.streetType[langColumn];
									}
								});
							}				
						}
						var calculateStreet = function(){
		            		if($scope.model.validCity){
		            			if($scope.model.streetType) {
		            				factory.getStreetsByCityAndStreetType($scope.model.validCity, $scope.model.streetType).then(function (data) {
		                                var streetsAux = JSOG.decode(data);
										streetsAux = streetsAux.sort(function (a, b) {
												  return a.name.localeCompare(b.name);
											});
		                                if (streetsAux.length > 0) {
											addStreetType(streetsAux);
											$scope.to.options = streetsAux;
		                                    $scope.model.hasStreets = true;
		                                } else {
		                                    $scope.to.options = [];
		                                    $scope.model.hasStreets = false;
		                                }
		                            }).catch(function (error) {
		                                $scope.to.options = [];
		                                $scope.model.hasStreets = false;
		                            });
		            			} else {
		            				factory.getStreetsByCity($scope.model.validCity, $scope.model.streetType).then(function (data) {
		                                var streetsAux = JSOG.decode(data);
		                                streetsAux = streetsAux.sort(function (a, b) {
											  	return a.name.localeCompare(b.name);
											});
		                                if (streetsAux.length > 0) {
											addStreetType(streetsAux);
		                                    $scope.to.options = streetsAux;
		                                    $scope.model.hasStreets = true;
		                                } else {
		                                    $scope.to.options = [];
		                                    $scope.model.hasStreets = false;
		                                }
		                            }).catch(function (error) {
		                                $scope.to.options = [];
		                                $scope.model.hasStreets = false;
		                            });
		            			}
		            			
		            		}else{
		            		    $scope.to.options = [];
		            		    $scope.model.hasStreets = false;
		            		}
                        }

                        if($scope.model && $scope.model.validCity){
                            calculateStreet();
                        }

		            	$rootScope.$on('modifyCountryTerritoryEvent', function(event, args) {
		            		$scope.model.validStreet = undefined;
                            $scope.to.options = [];
                        });
		            	$rootScope.$on('modifyStateTerritoryEvent', function(event, args) {
		            		$scope.model.validStreet = undefined;
                            $scope.to.options = [];
                        });
		            	$rootScope.$on('modifyCityTerritoryEvent', function (event, args) {
		            		$scope.model.validStreet = undefined;
                           	calculateStreet();
                        });
		            	$rootScope.$on('modifyStreetTypeTerritoryEvent', function (event, args) {
		            		$scope.model.validStreet = undefined;
                           	calculateStreet();
                        });
		            }],
                    onSelected: function($item) {
                        $rootScope.$broadcast('modifyStreetTerritoryEvent', { item: $item });
                    }
		         }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'street', fieldType: 'text', colClass: 'col-sm-12 p-l-2 p-r-2', required: false, label: 'global.thirds.literals.streetName', hideExpression: hideStreetString,
		            controller: ['$scope', function($scope) {
		            	$rootScope.$on('modifyCountryTerritoryEvent', function(event, args) {
		            		$scope.model.street = undefined;
                        });
		            	$rootScope.$on('modifyStateTerritoryEvent', function(event, args) {
		            		$scope.model.street = undefined;
                        });
		            	$rootScope.$on('modifyCityTerritoryEvent', function (event, args) {
		            		$scope.model.street = undefined;
                        });
		            }]
		         }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'validPseudoVia', fieldType: 'select', data:[], colClass: 'col-sm-12 p-l-2 p-r-2', required: false, label: 'global.thirds.literals.pseudoVia', hideExpression: hidePseudoVia,
		            controller: ['$scope', function($scope) {
		            	var calculatePseudoVia = function(args){
		            		if(!$scope.to.options){
		            			$scope.to.options = [];
		            		}
		            		if(($scope.model.validCity || args.city) && args && args.item && args.item.pseudoVies && args.item.pseudoVies.length > 0){
		            			$scope.to.options.length = 0;
		            			var options = [];
		            			_.forEach(args.item.pseudoVies,function(item){
		            				options.push(item.pseudoVia);
		            			});
		            			options = HelperService.sortArrayByLanguage(options);
		            			$scope.to.options = options;
		            			$scope.model.hasPseudoVia = true;
		            		}else{
		            			$scope.to.options.length = 0;
		            			$scope.model.hasPseudoVia = false;
		            		}
                        }
		            	if($scope.model.validCity){
		            		var field = $linq($scope.fields).firstOrDefault(undefined, "x => x.key == 'validCity'");
		            		if(field && field.templateOptions && field.templateOptions.options){
			            		var city = $linq(field.templateOptions.options).firstOrDefault(undefined,"x => x.id =="+$scope.model.validCity);
			            		if(city){
			            			calculatePseudoVia({item: city});
			            		}
		            		}
		            	}
		            	$rootScope.$on('modifyCountryTerritoryEvent', function(event, args) {
		            		$scope.model.validPseudoVia = undefined;
                            $scope.to.options = [];
                        });
		            	$rootScope.$on('modifyStateTerritoryEvent', function(event, args) {
		            		$scope.model.validPseudoVia = undefined;
                            $scope.to.options = [];
                        });
		            	$rootScope.$on('modifyCityTerritoryEvent', function (event, args) {
		            		$scope.model.validPseudoVia = undefined;
		            		calculatePseudoVia(args);
                        });
		            	$rootScope.$on('calculateCitiesTerritoryEvent', function (event, args) {
		            		calculatePseudoVia(args);
                        });
		            	
		            }]
		         }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'pseudoVia', fieldType: 'text', colClass: 'col-sm-12 p-l-2 p-r-2', required: false, label: 'global.thirds.literals.pseudoVia', hideExpression: hidePseudoViaString,
		            controller: ['$scope', function($scope) {
		            	$rootScope.$on('modifyCountryTerritoryEvent', function(event, args) {
		            		$scope.model.pseudoVia = undefined;
                        });
		            	$rootScope.$on('modifyStateTerritoryEvent', function(event, args) {
		            		$scope.model.pseudoVia = undefined;
                        });
		            	$rootScope.$on('modifyCityTerritoryEvent', function (event, args) {
		            		$scope.model.pseudoVia = undefined;
		            		if($scope.model.validCity && args.item && args.item.pseudoVies && args.item.pseudoVies.length > 0){
		            			$scope.model.hasPseudoVia = true;
		            		}else{
		            		    $scope.model.hasPseudoVia = false;
		            		}
                        });
		            	$rootScope.$on('calculateCitiesTerritoryEvent', function (event, args) {
		            		if(($scope.model.validCity || args.city) && args.item && args.item.pseudoVies && args.item.pseudoVies.length > 0){
		            			$scope.model.hasPseudoVia = true;
		            		}else{
		            		    $scope.model.hasPseudoVia = false;
		            		}
                        });
		            }]
		         }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'withoutNumber', fieldType: 'radio_checkbox', data: [{value: 1, label: $filter('translate')('global.thirds.literals.withoutNumber')}], colClass: 'col-sm-2 p-l-2', required: false, label: '', hideExpression: hidePostalGroup}, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'numberAddress', fieldType: 'text', colClass: 'col-sm-2', required: false, label: 'global.thirds.literals.number', hideExpression: showWithoutNumber, validators:{
                    "number": {
                        "expression": function (viewValue, modelValue, scope) {
                            var valid = true;
                            var validateNumbers = true;
							if(GlobalDataFactory.addressTypes && scope && scope.model && scope.model.addressType){
								var addTy = $linq(GlobalDataFactory.addressTypes).firstOrDefault(undefined,"x => x.id == "+scope.model.addressType);
								if(addTy && addTy.validateNumbers === false){
									validateNumbers = false;
								}
							}
                            if(validateNumbers && scope && scope.model && scope.model.territory === false){
								validateNumbers = false;
							}
                            if(validateNumbers && modelValue && (isNaN(modelValue) || modelValue.includes("."))){
                                valid = false;
                            }
                            return valid;
                        }
                    }
                } }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'numberAddresshidden', fieldType: 'hidden', colClass: 'col-sm-2', required: false, label: '', hideExpression: hideWithoutNumber}, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'numberAddress2', fieldType: 'text', colClass: 'col-sm-2', required: false, label: 'global.thirds.literals.number2', hideExpression: showWithoutNumber, validators:{
                    "number": {
                        "expression": function (viewValue, modelValue, scope) {
                            var valid = true;
							var validateNumbers = true;
							if(GlobalDataFactory.addressTypes && scope && scope.model && scope.model.addressType){
								var addTy = $linq(GlobalDataFactory.addressTypes).firstOrDefault(undefined,"x => x.id == "+scope.model.addressType);
								if(addTy && addTy.validateNumbers === false){
									validateNumbers = false;
								}
							}
                            if(validateNumbers && scope && scope.model && scope.model.territory === false){
								validateNumbers = false;
							}
                            if(validateNumbers && modelValue && (isNaN(modelValue) || modelValue.includes("."))){
                                valid = false;
                            }
                            return valid;
                        }
                    }
                } }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'numberAddress2hidden', fieldType: 'hidden', colClass: 'col-sm-2', required: false, label: '', hideExpression: hideWithoutNumber }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'bis', fieldType: 'text', colClass: 'col-sm-2', required: false, label: 'global.thirds.literals.bis', hideExpression: showWithoutNumber, validators:{
                    "number": {
                        "expression": function (viewValue, modelValue, scope) {
                            var valid = true;
                            if(modelValue && ((modelValue.includes && modelValue.includes(".")))){
                                valid = false;
                            }
                            return valid;
                        }
                    }
                }}, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'bishidden', fieldType: 'hidden', colClass: 'col-sm-2', required: false, label: '', hideExpression: hideWithoutNumber }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'bis2', fieldType: 'text', colClass: 'col-sm-2', required: false, label: 'global.thirds.literals.bis2', hideExpression: showWithoutNumber, validators:{
                    "number": {
                        "expression": function (viewValue, modelValue, scope) {
                            var valid = true;
                            if(modelValue && ((modelValue.includes && modelValue.includes(".")))){
                                valid = false;
                            }
                            return valid;
                        }
                    }
                }}, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'bis2hidden', fieldType: 'hidden', colClass: 'col-sm-2', required: false, label: '', hideExpression: hideWithoutNumber }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'kilometer', fieldType: 'text', colClass: 'col-sm-2 p-r-2', required: false, label: 'global.thirds.literals.kilometer', hideExpression: hidePostalGroup, validators:{
                    "number": {
                        "expression": function (viewValue, modelValue, scope) {
                            var valid = true;
                            if(modelValue && (isNaN(modelValue) || (modelValue+"").endsWith("."))){
                                valid = false;
                            }
                            return valid;
                        }
                    }
                }}, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'block', fieldType: 'text', colClass: 'col-sm-3 p-l-2', required: false, label: 'global.thirds.literals.block', hideExpression: hidePostalGroup }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'portal', fieldType: 'text', colClass: 'col-sm-3', required: false, label: 'global.thirds.literals.portal', hideExpression: hidePostalGroup }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'stair', fieldType: 'text', colClass: 'col-sm-2', required: false, label: 'global.thirds.literals.stair', hideExpression: hidePostalGroup }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'floor', fieldType: 'text', colClass: 'col-sm-2', required: false, label: 'global.thirds.literals.floor', hideExpression: hidePostalGroup }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'door', fieldType: 'text', colClass: 'col-sm-2 p-r-2', required: false, label: 'global.thirds.literals.door', hideExpression: hidePostalGroup }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'cadastralReference', fieldType: 'text', colClass: 'col-sm-4 p-l-2', required: false, label: 'global.territory.list.cadastral_reference', hideExpression: hideCadastralReferenceGroup  }, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'coordinateX', fieldType: 'text', colClass: 'col-sm-4 p-l-2', required: false, label: 'global.territory.list.coordinateX', hideExpression: hideCoordinatesGroup , validators:{
                    "number": {
                        "expression": function (viewValue, modelValue, scope) {
                            var valid = true;
                            if(modelValue && (isNaN(modelValue) || (modelValue+"").endsWith("."))){
                                valid = false;
                            }
                            return valid;
                        }
                    }
                }}, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'coordinateY', fieldType: 'text', colClass: 'col-sm-4 p-r-2', required: false, label: 'global.territory.list.coordinateY', hideExpression: hideCoordinatesGroup , validators:{
                    "number": {
                        "expression": function (viewValue, modelValue, scope) {
                            var valid = true;
                            if(modelValue && (isNaN(modelValue) || (modelValue+"").endsWith("."))){
                                valid = false;
                            }
                            return valid;
                        }
                    }
                }}, modal.annexaFormly.model));
				modal.annexaFormly.fields[0].fieldGroup.push(annexaFormFactory.getField({ type: 'field', id: 'observations', fieldType: 'text', colClass: 'col-sm-12 p-l-2 p-r-2', required: false, label: 'global.literals.observations' }, modal.annexaFormly.model));
				
            }
            AnnexaFormlyFactory.showModal('modalNewAddress', modal, saveNewAddress, false, false);
        };
        
        factory.canCreateAddress = function() {
        	var canCreate = false;
        	var haveAllPermisions = true;
 			if (AnnexaPermissionsFactory.havePermission('view_territory')) {
	        	_.forEach(GlobalDataFactory.addressTypes, function(value) {
	        		if(value.permissions && value.permissions.length > 0) {
	        			var createPerm = $linq(value.permissions).where("x => x.permissionType == 'CREATE'").where("x => x.city == null").toArray();
	        			if(createPerm && createPerm.length > 0) {
	        				_.forEach(createPerm, function(perm) {
	                            if(perm.profiles && !perm.city && perm.permissionType == 'CREATE') {
	                            	var addressTypeProfiles = $linq(perm.profiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
	                            	addressTypeProfiles = $linq(addressTypeProfiles).distinctBy("x => x.id").toArray();
	                            	if(AnnexaPermissionsFactory.haveProfile(addressTypeProfiles)) {
	                            		canCreate = true;
	                            	}
	                            }
	                        });
	        			} else {
	        				haveAllPermisions = false;
	        			}
	        		} else {
	        			haveAllPermisions = false;
	        		}
	            });
			}	
        	if(!haveAllPermisions || canCreate) {
        		return true;
        	}
        	return false;
        }

        return factory;
    }]);