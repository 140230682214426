/**
 * Created by osirvent on 08/04/2016.
 */
angular
    .module('annexaApp')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider

            .state('annexa.territory', {
                url: '/territory',
                redirectTo: 'annexa.territory.list',
                views: {
                    "": {
                        controller: 'TerritoryController',
                        templateUrl: './views/layout/territoryMain.html'
                    }
                },
                data: {
                    title: 'global.territory.toptitle',
                    displayName: 'global.territory.toptitle',
                    authenticate: true,
                    permissions: {
                        only: ['view_territory']
                    }
                },
                resolve: {
                	initializeList: ['TerritoryFactory', function (TerritoryFactory) {
                		return TerritoryFactory.initializeLists();
                    }]
                }
            })
            .state('annexa.territory.list', {
                url: '/list',
                views: {
                    "mainterritory@annexa.territory": {
                        controller: 'TerritoryListController',
                        templateUrl: './views/layout/territoryList.html'
                    }
                },
                data: {
                    title: 'global.territory.toptitle',
                    displayName: 'global.territory.list.toptitle',
                    authenticate: true,
                    permissions: {
                        only: 'view_territory'
                    }
                },
                resolve: {

                }
            });
    }]);